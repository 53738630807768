<template>
	<a :href="`//www.youtube.com/watch?v=${video.id}`" class="video-item">
		<div class="thumbnail">
			<img :src="video.snippet.thumbnails.medium.url" :alt="video.title">
			<div class="duration">{{ duration }}</div>
		</div>
		<div class="title" v-html="video.snippet.title"></div>
		<div class="metadata">
			<div class="channel">{{ video.snippet.channelTitle }}</div>
			<div class="info">{{ humanViewsCount }} views • {{ humanDate }} ago</div>
		</div>
	</a>
</template>

<script>
import { distanceInWordsStrict } from 'date-fns';
import { ISODurationToFormattedSeconds } from '../../utils/helpers.js';

export default {
	props: {
		video: {
			type: Object,
			required: true
		}
	},

	computed: {
		humanDate() {
			return distanceInWordsStrict(
				new Date(this.video.snippet.publishedAt),
				Date.now()
			);
		},

		duration() {
			return ISODurationToFormattedSeconds(this.video.contentDetails.duration);
		},

		humanViewsCount() {
			// This returns a number with one decimal, without rounding.
			// toFixed rounds to nearest.
			function toOneDecimal(number) {
				return Math.floor(number * 10) / 10;
			}

			const views = this.video.statistics.viewCount;
			// Billions
			if (views > 1000000000) return toOneDecimal(views / 1000000000) + 'B';

			// Tens of Millions
			if (views > 10000000) return Math.floor(views / 1000000) + 'M';

			// Millions
			if (views > 1000000) return toOneDecimal(views / 1000000) + 'M';

			// Above ten thousand
			if (views > 10000) return Math.floor(views / 1000) + 'K';

			// Above a thousand
			if (views > 1000) return toOneDecimal(views / 1000) + 'K';

			return views;
		}
	}
};
</script>
