<template>
	<div>
		<TextInput v-model="email" type="email" label="Email"></TextInput>
		<TextInput v-model="password" type="password" label="Password"></TextInput>
		<slot></slot>
		<button class="btn btn-main form-action">
			{{ actionLabel || 'Sign In' }}
		</button>
	</div>
</template>


<script>
import TextInput from '../Form/TextInput';

export default {
	components: {
		TextInput
	},

	props: {
		actionLabel: String
	},

	data() {
		return {
			email: '',
			password: ''
		};
	}
};
</script>
