<template>
	<div class="search input-text" :class="{'show-suggestions': showSuggestions}">
		<input
			v-model="value"
			type="text"
			placeholder="Search"
			@focus="inFocus = true"
			@blur="inFocus = false"
			@input="updateSuggestions"
			@keyup.up="moveActiveSuggestion"
			@keyup.down="moveActiveSuggestion"
			@keyup.enter="search"
		>
		<ul
			class="suggestions"
			@mouseenter="hoverSuggestions = true"
			@mouseleave="hoverSuggestions = false"
		>
			<li
				v-for="(suggestion, index) in suggestions"
				:key="suggestion"
				:class="{active: activeSuggestionIndex === index}"
				@mouseover="activeSuggestionIndex = index"
				@mouseup="search(suggestion)"
			>
				{{ suggestion }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			value: '',
			originalValue: '', // The value the user typed
			inFocus: false,
			hoverSuggestions: false,
			suggestions: [],
			activeSuggestionIndex: null
		};
	},

	computed: {
		showSuggestions() {
			return (
				// Input is not empty
				(this.value !== '' &&
					// There are some suggestions to show.
					this.suggestions.length &&
					// Input in focus
					this.inFocus) ||
				// or the suggestions are being hovered bt the mouse.
				this.hoverSuggestions
			);
		}
	},

	mounted() {
		// Add a callback to the body that the suggestions script will call when loaded.
		window.suggestionsCallback = this.suggestionsCallback.bind(this);
	},

	destroyed() {
		// Remove the global suggestion callback from the global scope.
		delete window.suggestionsCallback;
	},

	methods: {
		// Jump into the search page.
		search(query = this.value) {
			// Do nothing if the value is empty, or if it is an event.
			if (!query) return;

			// If query is an instance of event, this means that no argumetn was passed,
			// but vue passes the event automatically, so set the query to be equal to the input value.
			if (query instanceof Event) {
				query = this.value;
			}

			// Update the value of the field(in case it was clicked from the suggestions).
			this.value = query;

			// Else move into the search page, with the right query.
			this.$router.push({ name: 'search', query: { q: query } });

			// Remove the focus from the input field.
			document.activeElement.blur();

			// Remove the hover state from the suggestions element.
			this.hoverSuggestions = false;
		},

		updateSuggestions() {
			if (this.value === '') return;

			// Reset the active suggestion
			this.activeSuggestionIndex = null;

			// Create a script tag that loads the suggestions.
			const scriptElement = document.createElement('script');
			scriptElement.src = `https://suggestqueries.google.com/complete/search?client=youtube&ds=yt&q=${
				this.value
			}&callback=suggestionsCallback`;

			// Add event to remove itself on load, and add to the head.
			scriptElement.onload = () => scriptElement.remove();
			document.head.appendChild(scriptElement);
		},

		suggestionsCallback(data) {
			this.suggestions = data[1].map(item => item[0]);
		},

		moveActiveSuggestion(event) {
			if (event.code === 'ArrowDown') {
				// Check if we hit the last suggestion.
				// if we did, then disable the active suggestion.
				// +1 because `length` starts at 1.
				if (this.activeSuggestionIndex + 1 === this.suggestions.length) {
					this.activeSuggestionIndex = null;
					// Reset to the original value.
					this.value = this.originalValue;
					return;
				}

				// If the active suggestion is not active, then start from the bottom.
				if (this.activeSuggestionIndex === null) {
					this.activeSuggestionIndex = 0;
					// Save the original value.
					this.originalValue = this.value;
					// Update the value to the active suggestion.
					this.value = this.suggestions[this.activeSuggestionIndex];
					return;
				}

				// else just move forward.
				this.activeSuggestionIndex++;
				this.value = this.suggestions[this.activeSuggestionIndex];
				return;
			}

			// else we probably hit the up arrow.
			// Check if we hit the first suggestion.
			// if we did, then disable the active suggestion.
			if (this.activeSuggestionIndex === 0) {
				this.activeSuggestionIndex = null;
				// Reset to the original value.
				this.value = this.originalValue;
				return;
			}

			// If the active suggestion is not active, then start from the bottom.
			if (this.activeSuggestionIndex === null) {
				// -1 because length starts at 1.
				this.activeSuggestionIndex = this.suggestions.length - 1;
				// Save the original value.
				this.originalValue = this.value;
				// Update the value to the active suggestion.
				this.value = this.suggestions[this.activeSuggestionIndex];
				return;
			}

			// else just move up
			this.activeSuggestionIndex--;
			this.value = this.suggestions[this.activeSuggestionIndex];
		}
	}
};
</script>
