<template>
	<div class="player-controls" :class="{active: uiShown, 'editor-mode': editorMode}">
		<TagsProgressbar :tags="tags" :editor-mode="editorMode" :selected="selected"></TagsProgressbar>
		<div class="progressbar">
			<progress
				class="buffer-bar"
				:value="buffered"
				max="100"
			>
			</progress>
			<input
				class="playback-bar"
				type="range"
				step="any"
				min="0"
				:max="duration"
				:value="currentTime"
				:style="{'--value': progress + '%'}"
				@input="setCurrentTime($event.target.value)"
			/>
		</div>
		<div class="controls">
			<!-- play/pause -->
			<button class="play" @click="togglePlayback">
				<svg v-if="!playing" viewBox="0 0 24 24">
					<path fill="currentColor" d="M8 5v14l11-7z" /><path fill="none" d="M0 0h24v24H0z" />
				</svg>
				<svg v-else viewBox="0 0 24 24">
					<path fill="currentColor" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" /><path fill="none" d="M0 0h24v24H0z" />
				</svg>
			</button>

			<!-- volume -->
			<div class="volume">
				<button @click="toggleSound">
					<svg v-if="muted" viewBox="0 0 24 24">
						<path fill="currentColor" d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" /><path fill="none" d="M0 0h24v24H0z" />
					</svg>
					<svg v-else viewBox="0 0 24 24">
						<path fill="currentColor" d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" /><path fill="none" d="M0 0h24v24H0z" />
					</svg>
					<input
						type="range"
						min="0"
						max="100"
						step="0.01"
						:value="muted ? 0 : volume"
						:style="{'--value': muted ? 0 : volume + '%'}"
						@input="setVolume(Number($event.target.value))"
					/>
				</button>
			</div>
			<div class="time">
				{{ formattedTime }} / {{ formattedDuration }}
			</div>
			<button class="fullscreen" @click="toggleFullscreen">
				<svg v-if="fullscreen" width="24" height="24" viewBox="0 0 24 24">
					<path fill="currentColor" d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" />
				</svg>
				<svg v-else width="24" height="24" viewBox="0 0 24 24">
					<path fill="currentColor" d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
import TagsProgressbar from './TagsProgressbar';
import { formatSeconds, mousemoveend } from '../../utils/helpers.js';

export default {
	name: 'PlayerControls',

	components: {
		TagsProgressbar
	},

	props: {
		tags: {
			type: Array,
			required: true
		},
		editorMode: Boolean,
		selected: Object
	},

	data() {
		return {
			video: this.$parent.$refs.video,
			playing: false,
			muted: false,
			volume: 100,
			progress: 0,
			buffered: 0,
			currentTime: 0,
			duration: 0,
			formattedDuration: '0:00',
			fullscreen: false,
			autohideUi: true,
			uiShown: false
		};
	},

	computed: {
		formattedTime() {
			return formatSeconds(this.currentTime);
		}
	},

	mounted() {
		// Toggle autohide ui if not on editor mode.
		this.toggleAutohideUi(this.autohideUi);

		// Wait for the metadata of the video to arrive, and use it to update the state.
		this.video.addEventListener('loadedmetadata', () => {
			this.duration = this.video.duration;
			this.formattedDuration = formatSeconds(this.video.duration);
		});

		// Update the progress.
		this.video.addEventListener('timeupdate', () => {
			this.currentTime = this.video.currentTime;
			this.progress = (this.video.currentTime / this.duration) * 100;
		});
	},

	methods: {
		togglePlayback() {
			this.playing = !this.playing;

			if (this.playing) {
				this.video.play();
				return;
			}

			this.video.pause();
		},

		setVolume(volume) {
			this.volume = volume;
			this.video.volume = this.volume / 100;

			if (this.muted) this.toggleSound();
			if (this.volume === 0) this.muted = true;
		},

		toggleSound() {
			this.muted = !this.muted;
			this.video.muted = this.muted;

			if (this.volume === 0) this.setVolume(100);
		},

		setCurrentTime(time) {
			this.currentTime = time;
			this.video.currentTime = time;
			this.progress = (time / this.duration) * 100;
		},

		showUi() {
			// If the controls are already shown, return.
			if (this.uiShown === true) return;

			this.uiShown = true;
			mousemoveend(1000).then(() => {
				this.uiShown = false;
			});
		},

		toggleAutohideUi(turnOn) {
			this.autohideUi = turnOn;

			// Arrow function for implicit `this` binding.
			const mousemoveHandler = () => {
				this.showUi();
			};

			const mouseleaveHandler = () => {
				this.uiShown = false;
			};

			if (!this.autohideUi) {
				// Remove the event listeners.
				this.$parent.$refs.videoContainer.removeEventListener(
					'mousemove',
					mousemoveHandler
				);

				// Toggle controls off on mouse leave.
				this.$parent.$refs.videoContainer.removeEventListener(
					'mouseleave',
					mouseleaveHandler
				);
				return;
			}

			// Add event listeners for hiding the ui.

			// Toggle countdown for controlls to hide on mouse move over the player.
			this.$parent.$refs.videoContainer.addEventListener(
				'mousemove',
				mousemoveHandler
			);

			// Toggle controls off on mouse leave.
			this.$parent.$refs.videoContainer.addEventListener(
				'mouseleave',
				mouseleaveHandler
			);
		},
		toggleFullscreen() {
			if (this.fullscreen) {
				document.exitFullscreen();
				this.fullscreen = false;
				return;
			}

			// Using arrow function  for implicit "this" binding.
			const exitFullscreenHandler = () => {
				if (!document.fullscreenElement) {
					this.fullscreen = false;

					// Remove the event listener.
					document.removeEventListener(
						'fullscreenchange',
						exitFullscreenHandler
					);
				}
			};

			// Request fullscreen.
			this.$parent.$refs.videoContainer.requestFullscreen().then(() => {
				this.fullscreen = true;

				// Add event listener, user can exit by using esc key.
				document.addEventListener('fullscreenchange', exitFullscreenHandler);
			});
		}
	}
};
</script>
