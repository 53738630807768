<template>
	<section class="content oauth-callback" :class="{error}">
		<template v-if="error">
			<div class="error-icon">
				<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.98438 13H12.0156V15H9.98438V13ZM9.98438 5H12.0156V11H9.98438V5ZM10.9898 0C5.38359 0 0.84375 4.48 0.84375 10C0.84375 15.52 5.38359 20 10.9898 20C16.6062 20 21.1562 15.52 21.1562 10C21.1562 4.48 16.6062 0 10.9898 0ZM11 18C6.51094 18 2.875 14.42 2.875 10C2.875 5.58 6.51094 2 11 2C15.4891 2 19.125 5.58 19.125 10C19.125 14.42 15.4891 18 11 18Z" fill="white" />
				</svg>
			</div>
			{{ error }}<br />
			Please try again in a few moments.
		</template>
		<LoadingIcon v-else size="60"></LoadingIcon>
	</section>
</template>

<script>
import LoadingIcon from '../General/LoadingIcon';

export default {
	name: 'OAuthCallback',

	components: {
		LoadingIcon
	},

	data() {
		return {
			error: ''
		};
	},

	mounted() {
		// If the location doesnt have any parameters, redirect to login.
		if (location.search === '') {
			this.$router.push({ name: 'auth' });
			return;
		}

		this.$auth.finishOauthFlow().catch(error => {
			// If the user denied the access.
			if (error.message.startsWith('USER_CANCELLED')) {
				this.error = 'You did not allow us to use your account to log in.';
				console.error('Error while verifyng the Access Code:', error);
				return;
			}

			this.error = 'Error while verifyng your identity,';
			console.error('Error while verifyng the Access Code:', error);
		});
	}
};
</script>

