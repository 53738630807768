<template>
	<div ref="dropdown" class="dropdown" :class="{'dropdown-open': open}">
		<button class="dropdown-toggle" @click.stop="toggle">
			<slot name="toggle"></slot>
		</button>
		<div class="dropdown-body">
			<slot name="body"></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			open: false
		};
	},

	methods: {
		toggle() {
			this.open = !this.open;

			// run the next code only if the dorpodown is open.
			if (this.open === false) return;

			this.$nextTick().then(() => {
				window.addEventListener(
					'click',
					event => {
						// Don't do anything if the dropdown itself was clicked,
						// or if the reference to the dropdown doesn't exsist.
						if (
							this.$refs.dropdown === undefined ||
							this.$refs.dropdown.contains(event.target)
						)
							return;

						// Else, if clicked anywhere else, close the menu.
						this.open = false;
					},
					// Options.
					{ once: true }
				);
			});
		}
	}
};
</script>

