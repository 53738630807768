import axios from 'axios';

function responseHandler(response) {
	return response.data;
}

export class YoutubeAPI {
	constructor(apiKey) {
		function getEndpoint(path) {
			return `https://www.googleapis.com/youtube/v3/${path}?key=${apiKey}`;
		}

		this.apiKey = apiKey;
		this.endpoints = {
			search: getEndpoint('search'),
			videos: getEndpoint('videos')
		};
	}

	async search(query, params) {
		const defaultParams = {
			part: 'snippet',
			maxResults: '24',
			q: query
		};

		// Get the videos matching the query.
		// This returns limited info about the video,
		// so we are forced to make another request below and ask for
		// additional information about each video and them merge it.
		const searchList = await axios({
			url: this.endpoints.search,
			method: 'get',
			// Combine the default params with the requested ones.
			params: { ...defaultParams, ...params }
		}).then(responseHandler);

		// Get an array of the video's ids.
		const searchListIds = searchList.items.map(video => video.id.videoId);

		// request additional information about
		// the videos returned from the search above.
		const videosData = await this.listVideos(searchListIds, {
			// By default it asks for the snippet part,
			// but we dont need it here, because we already got it from the search.
			part: 'contentDetails,statistics'
		});

		// Combine the data received from the search(specifically the snippet)
		// with the data received from the additional request.
		videosData.items.forEach((video, index) => {
			video.snippet = searchList.items[index].snippet;
			return video;
		});

		// Since we want to keep all the data from the searchList like
		// the page token, number of results, etc, we replace the merged
		// videos array with the original array from the search, and return it.
		searchList.items = videosData.items;

		return searchList;
	}

	/*
	 * Retrieve information about an array of videos.
	 */
	listVideos(id, params) {
		const defaultParams = {
			part: 'snippet,contentDetails,statistics'
		};

		return axios({
			url: this.endpoints.videos,
			method: 'get',
			// Combine the default params with the requested ones.
			params: { ...defaultParams, ...params, id: id.join() }
		}).then(responseHandler);
	}
}
