/*
 * This file contains the heavy logic for the
 * Tag Editor in order to keep things clean on the Vue side.
 */
import { toPercent, findAncestor } from './helpers';

/*
 * This function calculates the new position of a tag based
 * on the mouse position.
 *
 * The event should be passed as the first argument.
 */
export function drag(e, tag, dragCallback) {
	// Try to get a parent container with the class "tags-container".
	const container = findAncestor(e.target, 'tags-container');

	// If a parent with the expected class couldent be found, throw.
	if (container === null)
		throw Error(
			'Cloud not resize the tag. Expected tag to be inside ".tags-container".'
		);

	// Get the box sizing info of the container.
	const containerBox = container.getBoundingClientRect();
	// Get the tag box sizing info(relative to left left and top).
	const tagBox = container
		.querySelector('.tag.being-edited')
		.getBoundingClientRect();

	// Helper that converts px to percentages(relative to progressbar width).
	// We bind it to the width of the container so we dont have to type it multiplt times.
	const toPct = toPercent.bind(null, containerBox.width);

	// Calculate the X position of the mouse relative to the tag on click.
	const mouseXoverTag = e.x - tagBox.left;

	// Calculate the maximum margin(the minumum is 0) for the tag.
	const maxMargin = 100 - tag.width;

	// Add event listener to mouse move, and pass it the movePosition function.
	// Arrow function for implicit binding.
	const mousemoveHandler = event => {
		// change the cursor
		document.body.style.cursor = 'grabbing';

		// Calculate the new margin based on the mouse position in percentages.
		const newMargin = toPct(event.x - mouseXoverTag - containerBox.left);

		if (newMargin < 0) {
			tag.margin = 0;
			return;
		}

		if (newMargin > maxMargin) {
			tag.margin = maxMargin;
			return;
		}

		tag.margin = newMargin;

		// Pass the new start time to the callback.
		if (dragCallback) dragCallback(tag.start);
	};

	// Start listening for mouse move(tag being dragged).
	window.addEventListener('mousemove', mousemoveHandler);

	// Return a promise that resolves once the
	// mouse released the tag and finished dragging.
	return new Promise(resolve => {
		// Cleanup the events.
		function mouseupHandler() {
			document.body.style.cursor = 'auto';
			window.removeEventListener('mousemove', mousemoveHandler);

			// resolve the promise.
			resolve();
		}

		// Listen for mouse up.
		window.addEventListener('mouseup', mouseupHandler, { once: true });
	});
}

/*
 * This function calculates the new margin and width
 * of the tag based on the position of the mouse while resizing.
 *
 * The event should be passed as the first argument.
 */
export function resize(e, tag, resizeCallback) {
	// Try to get a parent container with the class "tags-container".
	const container = findAncestor(e.target, 'tags-container');

	// If a parent with the expected class couldent be found, throw.
	if (container === null)
		throw Error(
			'Cloud not resize the tag. Expected tag to be inside ".tags-container".'
		);

	// Figure out which handle was grabbed.
	const side = e.target.classList.contains('right-handle') ? 'right' : 'left';
	// Get the box sizing info of the container.
	const containerBox = container.getBoundingClientRect();
	// Get the tag box sizing info(relative to left left and top).
	const tagBox = container
		.querySelector('.tag.being-edited')
		.getBoundingClientRect();
	// Mouse X position over the handle.
	// Needed so that the mouse stays the same distance
	// from the tag(the tag body not the handle) when resizing it.
	const mouseXRelativeToTag =
		side === 'right' ? tagBox.right - e.x : tagBox.left - e.x;
	// Minimal with of tag in percentages;
	const minWidth = 1;
	// Max width(percentages) based on the side.
	const maxWidth = side === 'right' ? 100 - tag.margin : tag.margin + tag.width;

	// Helper that converts px to percentages(relative to progressbar width).
	// We bind it to the width of the container so we dont have to type it multiplt times.
	const toPct = toPercent.bind(null, containerBox.width);

	// On mouse move start the resizing.
	// Arrow function for implicit binding.
	const mousemoveHandler = event => {
		// Change the cursor to a grabbing hand.
		document.body.style.cursor = 'ew-resize';

		// Subtract/add the position of the mouse
		// when clicked on the handle so that it stays on
		// that position relative to the tag when resizing.
		const mouseX = event.x + mouseXRelativeToTag;

		// If the left handle is being grabbed then the
		// logic will be a little bi different, mainly beacuse of the margin.
		if (side === 'left') {
			// Calculate the new width and margin.
			const newWidth = toPct(tagBox.right - mouseX);
			const newMargin = toPct(mouseX - containerBox.left);

			// If the new width is smaller then ne minimal allowed width for a tag.
			if (newWidth < minWidth) {
				// This is very important, it fixes a very annoying bug.
				// We calculate the new margin based only on positions previously known
				// on the bginning of the resizing, and not based on the mouse move event data.
				tag.margin = tag.margin + tag.width - minWidth;

				// Set the "new" width to the minimally alowed tag width.
				tag.width = minWidth;
				return;
			}

			// If the new width is bigger than the largest possible width
			// by grabbing the left handle.
			if (newWidth > maxWidth) {
				tag.width = maxWidth;
				tag.margin = 0; // fixed margin, no need to calculate.
				return;
			}

			// Else just use the calculations made above and return.
			tag.width = newWidth;
			tag.margin = newMargin;

			// Call the callback with the new start time
			if (resizeCallback) resizeCallback(tag.start);
			return;
		}

		// This is the logic for the right handle.
		// There is a return on the end of the `side === "left"` statement.
		// this one is simpler because there is no need to change the margins.

		// Calculate new width
		const newWidth = toPct(mouseX - tagBox.left, containerBox.width);

		// If the new width is smaller than the minimally allowed.
		if (newWidth < minWidth) {
			tag.width = minWidth;
			return;
		}

		// If the new width is larger than the allowed.
		if (newWidth > maxWidth) {
			tag.width = maxWidth;
			return;
		}

		// Update the width.
		tag.width = newWidth;

		// Call the callback with the new end time.
		if (resizeCallback) resizeCallback(tag.end);
	};

	// Start listening for mouse move(tag being resized).
	window.addEventListener('mousemove', mousemoveHandler);

	// Return a promise that resolves once the
	// mouse released the tag's resizing handler.
	return new Promise(resolve => {
		// Cleanup the events.
		function mouseupHandler() {
			document.body.style.cursor = 'auto';
			window.removeEventListener('mousemove', mousemoveHandler);

			resolve();
		}

		// Start listening for mouse up.
		window.addEventListener('mouseup', mouseupHandler, { once: true });
	});
}

/*
 * Check if a tag has changed and return a boolean.
 */
export function hasDifferentTime(before, after) {
	if (before.start === after.start || before.end === after.end) {
		return false;
	}

	return true;
}
