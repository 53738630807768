<template>
	<div class="auth">
		<routerLink v-if="$auth.user === null" to="/auth">
			Sign in
		</routerLink>

		<Dropdown v-else>
			<template v-slot:toggle>
				<img class="avatar" :src="$auth.user.photoUrl" alt="Avatar">
			</template>
			<template v-slot:body>
				<header>
					<img class="avatar" :src="$auth.user.photoUrl" alt="Avatar">
					<div>
						<div class="username">
							{{ $auth.user.displayName }}
						</div>
						<div class="email">
							{{ $auth.user.email }}
						</div>
					</div>
				</header>
				<ul class="menu-list">
					<li v-for="item in menu" :key="item.title" class="menu-items">
						<routerLink :to="item.url">
							{{ item.title }}
						</routerLink>
					</li>
				</ul>
			</template>
		</Dropdown>
	</div>
</template>

<script>
import Dropdown from '../General/Dropdown';
export default {
	components: {
		Dropdown
	},

	data() {
		return {
			menu: [
				{ title: 'My videos', url: '/my-videos/' },
				{ title: 'Sign out', url: '/auth/signout' }
			]
		};
	}
};
</script>

