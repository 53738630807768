<template>
	<section class="content sign-in">
		<SocialAuth></SocialAuth>
		<div class="separator">
			<span class="label">
				OR
			</span>
		</div>
		<EmailPassword></EmailPassword>
	</section>
</template>

<script>
import SocialAuth from './SocialAuth';
import EmailPassword from './EmailPassowrdForm';

export default {
	name: 'SignInForm',
	components: {
		SocialAuth,
		EmailPassword
	},

	data() {
		return {
			email: '',
			password: ''
		};
	}
};
</script>

