<template>
	<section class="content sign-up-options">
		<SocialAuth prefix="Sign up"></SocialAuth>
		<div class="input-link">
			By signing up you agree to Void's<br>
				<a href="#">
					Terms of Service
				</a> and
				<a href="#">
					Privacy Policy.
				</a>
		</div>
	</section>
</template>

<script>
import SocialAuth from './SocialAuth';

export default {
	components: {
		SocialAuth
	}
};
</script>
