<template>
	<section v-if="videos.length" class="videos-list">
		<Video v-for="video in videos" :key="video.etag" :video="video"></Video>
	</section>
	<section v-else class="videos-list">
		<img v-for="n in 8" :key="n" class="video-placeholder" :src="placeholderSrc" />
	</section>
</template>

<script>
import Video from './VideoItem';
import placeholderSrc from '../../assets/images/video-placeholder.svg';

export default {
	components: {
		Video
	},

	props: {
		videos: {
			required: true,
			type: Array
		}
	},

	data() {
		return {
			placeholderSrc
		};
	}
};
</script>

