// Full documentation for the "identitytoolkit" aPI can be found here:
// https://developers.google.com/resources/api-libraries/documentation/identitytoolkit/v3/python/latest/identitytoolkit_v3.relyingparty.html

async function handleIdentityToolkitResponse(res) {
	const data = await res.json();

	// Check if there is an error.
	if (data.error) throw data.error;

	// else return the data.
	return data;
}

export class Provider {
	constructor({ provider, redirectUri, customParams, scope, endpoint }) {
		const allowedProviders = ['google', 'facebook', 'github', 'twitter'];
		const defaultScopes = {
			google: 'profile',
			facebook: 'email'
		};

		// Validate the name.
		if (!allowedProviders.includes(provider))
			throw Error(
				`"${provider}" Is not a valid provider name, The supported providers are "${allowedProviders.join(
					', '
				)}"`
			);

		// Validate that required params are not undefined.
		if (redirectUri === undefined)
			throw Error(
				'Provider requires the "redirectUri" prop in order to create an instance.'
			);

		if (endpoint === undefined)
			throw Error(
				'Provider requires the "redirectUri" prop in order to create an instance.'
			);

		this.endpoint = endpoint;
		this.provider = provider;
		this.redirectUri = redirectUri;
		this.customParams = customParams;
		this.scope = scope || defaultScopes[provider];
	}

	// If successfull returns an object with a sessionId and Url for authentication.
	getAuthUri() {
		return fetch(this.endpoint, {
			method: 'POST',
			body: JSON.stringify({
				providerId: this.provider + '.com',
				continueUri: this.redirectUri,
				oauthScope: this.scope,
				customParameter: this.customParams
			})
		}).then(handleIdentityToolkitResponse);
	}
}

export class AuthFlow {
	constructor({ apiKey, redirectUri }) {
		function getEndpoint(path) {
			return `https://www.googleapis.com/identitytoolkit/v3/relyingparty/${path}?key=${apiKey}`;
		}

		if (redirectUri === undefined)
			throw Error(
				'AuthFlow requires the "redirectUri" prop in order to create an instance.'
			);

		this.apiKey = apiKey;
		this.redirectUri = redirectUri;
		this.providers = {};
		this.endpoints = {
			token: `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
			verifyCustomToken: getEndpoint('verifyCustomToken'),
			signupNewUser: getEndpoint('signupNewUser'),
			verifyPassword: getEndpoint('verifyPassword'),
			verifyAssertion: getEndpoint('verifyAssertion'),
			createAuthUri: getEndpoint('createAuthUri'),
			getOobConfirmationCode: getEndpoint('getOobConfirmationCode'),
			resetPassword: getEndpoint('resetPassword'),
			setAccountInfo: getEndpoint('setAccountInfo'),
			getAccountInfo: getEndpoint('getAccountInfo'),
			deleteAccount: getEndpoint('deleteAccount')
		};
	}

	// Adds a provider to the instance.
	addProvider(conf) {
		// If a rediredtUri was not provided,
		// try to use the redirectUri from the instance.
		conf.redirectUri = conf.redirectUri || this.redirectUri;

		// Set the endpoint for authUri generation.
		conf.endpoint = conf.endpoint || this.endpoints.createAuthUri;

		// Add the provider the the providers list.
		this.providers[conf.provider] = new Provider(conf);
	}

	// Verify a returned access token from an OAuth callback.
	// Returns the access token or an error.
	async exchangeCodeForToken(requestUri, sessionId) {
		return fetch(this.endpoints.verifyAssertion, {
			method: 'POST',
			body: JSON.stringify({
				requestUri,
				sessionId,
				returnIdpCredential: true,
				returnSecureToken: true
			})
		}).then(handleIdentityToolkitResponse);
	}

	// Start auth flow of a federated id provider.
	async startOauthFlow(providerName, localRedirectUri) {
		// Get an array of the allowed providers names.
		const allowedProviders = Object.keys(this.providers);

		// Verify that the requested provider is indeed configured.
		if (!allowedProviders.includes(providerName))
			throw Error(
				`"${providerName}" is not configured in this instance AuthFlow`
			);

		try {
			// Get the url and other data neccessary for the authentication.
			const { authUri, sessionId } = await this.providers[
				providerName
			].getAuthUri();

			// If the argument redirectUri was passed, then save it in sessionStorage.
			// This is not the redirectUri sent to the Provider, this is an internal redirectUri
			// used internally for routing within the app after the Authorization was performed.
			if (localRedirectUri)
				sessionStorage.setItem(
					`Auth:Redirect:${this.apiKey}`,
					localRedirectUri
				);
			// Save the sessionId that we just received in the local storage.
			sessionStorage.setItem(`Auth:SessionId:${this.apiKey}`, sessionId);

			// Finnaly - redirect the page to the auth endpoint.
			location.href = authUri;
		} catch (error) {
			// If it failed to initialize the Auth flow for any reason,
			// remove all the temporary objects from the sessionStorage.
			sessionStorage.removeItem(`Auth:Redirect:${this.apiKey}`);
			sessionStorage.removeItem(`Auth:SessionId:${this.apiKey}`);

			// Throw the error.
			throw error;
		}
	}

	// Saves the credentials along with the access token and id token in localStorage.
	persistSession(credentials) {
		localStorage.setItem(
			`Auth:User:${this.apiKey}`,
			JSON.stringify(credentials)
		);
	}

	// This code runs after the Federated Id Provider
	// returned the auth Code to our page, and exchanges it with
	// User info, Access Token and ID token.
	async finishOauthFlow(responseUrl = window.location.href) {
		// Get the local redirect URI if it exsists.
		const redirectUri = sessionStorage.getItem(`Auth:Redirect:${this.apiKey}`);
		// Get the sessionId we received before the redirect from sessionStorage.
		const sessionId = sessionStorage.getItem(`Auth:SessionId:${this.apiKey}`);

		// Try to exchange the Auth Code for Token and user
		// data and save the data to the local storage.
		const userData = await this.exchangeCodeForToken(responseUrl, sessionId);
		this.persistSession(userData);

		// Now clean up the temporary objects from the local storage.
		// This includes the sessionId and the local redirectURI.
		sessionStorage.removeItem(`Auth:Redirect:${this.apiKey}`);
		sessionStorage.removeItem(`Auth:SessionId:${this.apiKey}`);

		// If a local redirect uri was set, redirect to it
		// else, just get rid of the params in the location bar.
		window.location.href = redirectUri || location.origin + location.pathname;
	}

	// Remove the session info from the localStorage.
	signOut() {
		localStorage.removeItem(`Auth:User:${this.apiKey}`);
	}

	async signUpWithPassword() {}

	get user() {
		if (!this._user) {
			this._user = JSON.parse(localStorage.getItem(`Auth:User:${this.apiKey}`));
		}

		return this._user;
	}
}
