import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { auth, youtube } from './api.js';

// Add auth to all the view instances.
Vue.prototype.$auth = auth;
// Add the youtube api to the instances.
Vue.prototype.$youtube = youtube;

// Render the vue app.
new Vue({
	el: '#app',
	router,
	render(h) {
		return h(App);
	}
});
