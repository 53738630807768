<template>
	<section class="tags-inspector">
		<header>
			<TextInput v-model="query" class="input-text-small tags-search" label="Search tags"></TextInput>
			<button class="add-tag" @click="createTag">
				<svg width="18" height="18" viewBox="0 0 18 18" fill="none">
					<path d="M14 10H10V14H8V10H4L4 8H8V4H10V8H14V10Z" fill="currentColor" />
				</svg>
				<span>Add new tag</span>
			</button>
		</header>
		<div class="tags-inspector-body">
			<div ref="tagsContainer" class="tags-inspector-tags-container">
				<TagCard v-for="tag in filteredTags" :key="tag.id" :tag="tag" :selected="tag === selected"></TagCard>
			</div>
		</div>
	</section>
</template>

<script>
import TagCard from './TagCard';
import TextInput from '../Form/TextInput';
import Fuse from 'fuse.js';

export default {
	components: {
		TagCard,
		TextInput
	},

	props: {
		tags: {
			type: Array,
			required: true
		},
		selected: Object
	},

	data() {
		return {
			query: ''
		};
	},

	computed: {
		tagsIndex() {
			return new Fuse(this.tags, {
				keys: ['name', 'description']
			});
		},

		// Tags filtered by the query.
		filteredTags() {
			if (this.query === '') return this.tags;
			return this.tagsIndex.search(this.query);
		}
	},

	methods: {
		toggleModal() {
			this.$parent.toggleModal();
		},

		setCurrentTime(time) {
			this.$parent.video.currentTime = time;
		},

		selectTag(tag) {
			this.$parent.selectTag(tag);
		},

		exitEditorMode() {
			this.$parent.exitEditorMode();
		},

		createTag() {
			this.$parent.createTag();
			this.$nextTick(() => {
				this.$refs.tagsContainer.scrollLeft = this.$refs.tagsContainer.scrollWidth;
			});
		}
	}
};
</script>

