<template>
	<div id="auth">
		<section class="modal">
			<header>
				<RouterLink :to="{name: 'home'}">
					<AppLogo :gray="logoIsGray"></AppLogo>
				</RouterLink>
				<nav v-if="$route.meta.menu">
					<ul>
						<li v-for="item in menuItems" :key="item.title">
							<RouterLink :to="item.link">
								{{ item.title }}
							</RouterLink>
						</li>
					</ul>
				</nav>
			</header>
			<div class="content-wrap">
				<Transition
					@enter="startTransition"
					@after-enter="resetTransition"
					@leave="startTransition"
					@after-leave="resetTransition"
				>
					<RouterView></RouterView>
				</Transition>
			</div>
		</section>
	</div>
</template>

<script>
import Logo from '../General/Logo';

export default {
	name: 'Auth',
	components: {
		AppLogo: Logo
	},

	data() {
		return {
			logoIsGray: true,
			currentForm: 'SignIn',
			menuItems: [
				{ link: '/auth/signin', title: 'Sign In' },
				{ link: '/auth/signup', title: 'Create Account' }
			]
		};
	},

	methods: {
		changeForm(event, formName) {
			event.preventDefault();
			if (formName === this.currentForm) return;
			this.currentForm = formName;
		},
		startTransition(el) {
			const container = document.querySelector('#auth .content-wrap');
			container.style.overflow = 'hidden'; // hidden just for the transitions.
			container.style.height = el.offsetHeight + 'px';
		},
		resetTransition() {
			const container = document.querySelector('#auth .content-wrap');
			container.style.overflow = 'initial'; // Needed to be auto for the input outlines.
			container.style.height = 'auto';
		}
	}
};
</script>

