import ISODuration from 'iso8601-duration';

export function toPercent(full, part) {
	return (part / full) * 100;
}

export function fromPercent(full, percent) {
	return (percent * full) / 100;
}

// Finds a parent DOM element with a specific class name.
export function findAncestor(el, cls) {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
}

// Converts seconds to human readable time.
export function formatSeconds(fullSeconds, withMil) {
	const hours = Math.floor(fullSeconds / 3600);
	let minutes = Math.floor((fullSeconds - hours * 3600) / 60);
	let seconds = Math.floor(fullSeconds % 60);

	// Force seconds to be at least two digits.
	if (seconds < 10) {
		seconds = '0' + String(seconds);
	}

	let formatted = `${minutes}:${seconds}`;

	if (hours > 0) {
		// Only when the video is longer than an hour,
		// force two digits minutes.
		if (minutes < 9) {
			minutes = '0' + minutes;
		}

		formatted = `${hours}:${minutes}:${seconds}`;
	}

	if (withMil) {
		// If milliseconds are needed, then calculate and append them.
		formatted += parseFloat(fullSeconds % 1)
			.toFixed(2)
			.replace('0.', '.');
	}

	return formatted;
}

export function mousemoveend(delay) {
	// return a promise that resolves on mouse move end + delay.
	return new Promise(resolve => {
		let timeout;

		function handleMousemove() {
			// If the timeout already started, clear it.
			if (timeout !== undefined) {
				clearTimeout(timeout);
			}

			// If the the timeout didnt start yet.
			timeout = setTimeout(() => {
				// Resolve the promise.
				window.removeEventListener('mousemove', handleMousemove);
				resolve();
			}, delay);
		}

		window.addEventListener('mousemove', handleMousemove);
	});
}

export function ISODurationToSeconds(string) {
	return ISODuration.toSeconds(ISODuration.parse(string));
}

export function ISODurationToFormattedSeconds(string) {
	return formatSeconds(ISODurationToSeconds(string));
}

export function formatViewCount(number) {
	return number;
}
