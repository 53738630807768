<template>
	<div id="app">
		<Header></Header>
		<div id="search-results" class="width-wrap">
			<VideoList :videos="videos"></VideoList>
		</div>
	</div>
</template>

<script>
import Header from './Header/Header';
import VideoList from './General/VideosList';

export default {
	name: 'SearchResults',

	components: {
		Header,
		VideoList
	},

	data() {
		return {
			videos: [],
			pageInfo: null,
			nextPageToken: null
		};
	},

	/*
	 * Re-search on query param change.
	 * Todo: Check if there is a better way to do this.
	 */

	watch: {
		'$route.query.q': function() {
			this.searchVideos(this.$route.query.q);
		}
	},

	mounted() {
		// if there is no query param in the url, redirect home.
		if (!this.$route.query.q) {
			this.$router.push({ name: 'home' });
			return;
		}

		this.searchVideos(this.$route.query.q);
	},

	methods: {
		searchVideos(query) {
			// Remove the current videos, this will trigger the placeholders.
			this.videos = [];

			this.$youtube.search(query, { type: 'video' }).then(searchList => {
				this.videos = searchList.items;
				this.pageInfo = searchList.pageInfo;
				this.nextPageToken = searchList.nextPageToken;
			});
		}
	}
};
</script>

