import Vue from 'vue';
import Router from 'vue-router';
import Home from './components/Home.vue';
import Search from './components/Search.vue';
import Uploads from './components/Uploads.vue';
import Auth from './components/Auth/Auth.vue';
import SignIn from './components/Auth/SignIn.vue';
import SignUp from './components/Auth/SignUp.vue';
import SignOut from './components/Auth/SignOut.vue';
import OAuth from './components/Auth/OAuth.vue';
import VideoPage from './components/VideoPage/VideoPage.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	linkActiveClass: 'active',
	linkExactActiveClass: 'exact-active',
	routes: [
		{ name: 'home', path: '/', component: Home },
		{ name: 'search', path: '/search', component: Search },
		{ name: 'uploads', path: '/uploads', component: Uploads },
		{ name: 'video', path: '/video', component: VideoPage },
		{
			name: 'auth',
			path: '/auth',
			component: Auth,
			redirect: '/auth/signin',
			children: [
				{ path: 'signin', component: SignIn, meta: { menu: true } },
				{ path: 'signup', component: SignUp, meta: { menu: true } },
				{ path: 'signout', component: SignOut },
				{ path: 'oauth', component: OAuth }
			]
		}
	]
});
