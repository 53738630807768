<template>
	<section class="content oauth-callback">
		<LoadingIcon size="60"></LoadingIcon>
	</section>
</template>

<script>
import LoadingIcon from '../General/LoadingIcon';

export default {
	name: 'SignOut',

	components: {
		LoadingIcon
	},

	mounted() {
		this.$auth.signOut();
		setTimeout(() => {
			this.$router.push({ name: 'home' });
		}, 1000);
	}
};
</script>

