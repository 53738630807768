<template>
	<header id="header">
		<div class="width-wrap">
			<RouterLink to="/" class="logo">
				<img src="../../assets/logo.svg" alt="Void">
			</RouterLink>
			<Search></Search>
			<Auth></Auth>
		</div>
	</header>
</template>

<script>
import Search from './Search';
import Auth from './Auth';

export default {
	components: {
		Search,
		Auth
	}
};
</script>
