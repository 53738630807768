import { AuthFlow } from './utils/auth.js';
import { YoutubeAPI } from './utils/youtube.js';

const apiKey = 'AIzaSyBWnEizheHsChtgGsbO7ZY9ITcyccnf0WM';

/*
 * Initialize the auth api.
 */
export const auth = new AuthFlow({
	apiKey,
	redirectUri: 'http://localhost:1234/auth/oauth'
});

auth.addProvider({
	provider: 'google',
	scope: 'profile https://www.googleapis.com/auth/youtube.readonly'
});

/*
 * Initialize the Youtube api.
 */
export const youtube = new YoutubeAPI(apiKey);
