<template>
	<div id="video-page">
		<Player :tags="tags" :editor-mode="editorMode" :selected="selected" @ready="computeTags"></Player>
		<VideoInfo></VideoInfo>
		<TagsInspector :tags="tags" :selected="selected"></TagsInspector>
		<Comments></Comments>
		<ShareModal v-if="showModal"></ShareModal>
	</div>
</template>

<script>
import Player from '../Player/Player';
import VideoInfo from '../VideoPage/VideoInfo';
import TagsInspector from '../VideoPage/TagsInspector';
import Comments from '../VideoPage/Comments';
import ShareModal from '../VideoPage/ShareModal';

import rawTags from '../../data.json';
import Tag from '../../models/Tag.js';
import Vue from 'vue';

export default {
	name: 'VideoPage',

	components: {
		Player,
		VideoInfo,
		TagsInspector,
		Comments,
		ShareModal
	},

	data() {
		return {
			selected: null,
			editorMode: false,
			video: null,
			tags: [],
			showModal: false
		};
	},

	methods: {
		// This will be called once the video element is ready.
		// Only when its ready(the metadata is ready), we will use
		// it in order to instantiate new Tag instances.
		computeTags(video) {
			this.video = video;

			this.tags = rawTags.map(tag =>
				// Wrap with an observable object in order to track the changes.
				Vue.observable(new Tag(tag, video.duration || 0))
			);
		},

		toggleModal() {
			this.showModal = !this.showModal;
		},

		selectTag(tag) {
			this.selected = tag;
			this.editorMode = true;
		},

		exitEditorMode() {
			this.selected = null;
			this.editorMode = false;
		},

		createTag() {
			this.tags.push(
				Vue.observable(
					new Tag(
						{
							id: String(Date.now()),
							color: '',
							name: '',
							description: '',
							start: this.video.currentTime,
							end: this.video.currentTime + 1
						},
						this.video.duration || 0
					)
				)
			);

			this.selectTag(this.tags[this.tags.length - 1]);
		}
	}
};
</script>
