<template>
	<div id="app">
		<Header></Header>
		<div class="width-wrap">
			This is the home page.
		</div>
	</div>
</template>

<script>
import Header from './Header/Header';

export default {
	name: 'Home',

	components: {
		Header
	},
	mounted() {
		console.dir(this.$auth.user);
	}
};
</script>

