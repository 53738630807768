<template>
	<div id="app">
		<Header></Header>
		<div class="width-wrap">
			Uploads page.
		</div>
	</div>
</template>

<script>
import Header from './Header/Header';

export default {
	components: {
		Header
	}
};
</script>
