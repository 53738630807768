<template>
	<div class="tag-wrap">
		<div class="tag-time">
			{{ tag.humanStart }} - {{ tag.humanEnd }}
		</div>
		<div v-if="selected" class="tag-editor">
			<input v-model="tag.color" type="color" class="color-picker">
			<button class="save" @click="$parent.exitEditorMode()">
				Save
			</button>
			<TextInput v-model="tag.name" label="Title" class="input-text-small"></TextInput>
			<TextInput v-model="tag.description" label="Description" class="input-text-small"></TextInput>
		</div>
		<template v-else>
			<a
				href="#"
				class="tag"
				@click.prevent="$parent.setCurrentTime(tag.start)"
				@dblclick.prevent="$parent.setCurrentTime(tag.end)"
			>
				<div class="tag-color" :style="{'background-color': tag.color}"></div>
				<div class="tag-title">
					{{ tag.name }}
				</div>
				<div v-if="tag.description" class="tag-description">
					{{ tag.description }}
				</div>
			</a>
			<div class="tag-options">
				<a href="#" @click.prevent="$parent.toggleModal()">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path d="M14 8V4L21 11L14 18V13.9C9 13.9 5.5 15.5 3 19C4 14 7 9 14 8Z" fill="currentColor" />
					</svg>
				</a>

				<a href="#" @click.prevent="$parent.selectTag(tag)">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path d="M3 17.2525V21.0025H6.75L17.81 9.9425L14.06 6.1925L3 17.2525ZM20.71 7.0425C21.1 6.6525 21.1 6.0225 20.71 5.6325L18.37 3.2925C17.98 2.9025 17.35 2.9025 16.96 3.2925L15.13 5.1225L18.88 8.8725L20.71 7.0425Z" fill="currentColor" />
					</svg>
				</a>
			</div>
		</template>
	</div>
</template>

<script>
import TextInput from '../Form/TextInput';
export default {
	components: {
		TextInput
	},

	props: {
		tag: {
			type: Object,
			required: true
		},
		selected: Boolean
	}
};
</script>

