import { toPercent, fromPercent, formatSeconds } from '../utils/helpers';

export default class Tag {
	constructor(tag, videoDuration) {
		// Validate that duration was provided.
		if (!videoDuration && typeof videoDuration !== 'number') {
			throw Error('Cannot instantiate new Tag without a duration.');
		}

		// Copy the tag's params into the instance object.
		Object.assign(this, tag);

		// Keep a copy of the original tag to track changes.
		this._original = { ...tag };

		// The duration of the source video.
		this.videoDuration = videoDuration;
	}

	get margin() {
		return toPercent(this.videoDuration, this.start);
	}

	set margin(margin) {
		const newStart = fromPercent(this.videoDuration, margin);
		const duration = this.end - this.start;
		this.start = newStart;
		this.end = newStart + duration;
	}

	get width() {
		return toPercent(this.videoDuration, this.end - this.start);
	}

	set width(width) {
		this.end = fromPercent(this.videoDuration, this.margin + width);
	}

	get humanStartWithMillis() {
		return formatSeconds(this.start, true);
	}

	get humanEndWithMillis() {
		return formatSeconds(this.end, true);
	}

	get humanStart() {
		return formatSeconds(this.start);
	}

	get humanEnd() {
		return formatSeconds(this.end);
	}
}
