<template>
	<div ref="videoContainer" class="player">
		<video ref="video">
			<source src="../../assets/video.mp4" type="video/mp4">
		</video>
		<Controls :tags="tags" :editor-mode="editorMode" :selected="selected"></Controls>
	</div>
</template>

<script>
import Controls from './Controls';

export default {
	name: 'Player',

	components: {
		Controls
	},

	props: {
		tags: {
			type: Array,
			required: true
		},
		editorMode: Boolean,
		selected: Object
	},

	mounted() {
		this.$refs.video.addEventListener('loadedmetadata', () => {
			this.$emit('ready', this.$refs.video);
		});
	}
};
</script>


