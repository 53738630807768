<template>
	<div class="tags-progressbar">
		<div v-show="tooltipTag" ref="tagsTooltip" class="player-tooltip" :style="{ left: tooltipMargin + '%' }">
			{{ tooltipTag && tooltipTag.name }}
		</div>
		<div ref="tagsContainer" class="tags-container">
			<Tag
				v-for="tag in tags"
				:key="tag.id"
				:tag="tag"
				:being-edited="tag === selected"
				:editor-mode="editorMode"
			></Tag>
		</div>
	</div>
</template>

<script>
import Tag from './Tag';
import { drag, resize } from '../../utils/tagEditor.js';

export default {
	name: 'TagsProgressbar',

	components: {
		Tag
	},

	props: {
		tags: {
			type: Array,
			required: true
		},
		editorMode: Boolean,
		selected: Object
	},

	data() {
		return {
			tooltipTag: null, // Tag shown on the tooltip
			tooltipMargin: 0
		};
	},

	methods: {
		showTooltip(tag) {
			this.tooltipTag = tag;
			this.$nextTick().then(this.calcTooltipMargin.bind(this));
		},

		calcTooltipMargin() {
			if (this.$refs.tagsTooltip === undefined) return 0;
			if (this.tooltipTag === undefined) return 0;

			// calculate the width of the tooltip in percentages
			// relative to the tags container width, because the tags margin uses percentages.
			const tooltipWidthPct =
				(this.$refs.tagsTooltip.offsetWidth /
					this.$refs.tagsContainer.offsetWidth) *
				100;

			// The max margin without overflowing to the right.
			const maxMargin = 100 - tooltipWidthPct;
			// calculate the new margin based on the position of the tag.
			const margin =
				this.tooltipTag.margin +
				this.tooltipTag.width / 2 -
				tooltipWidthPct / 2;

			if (margin > maxMargin) return maxMargin;
			this.tooltipMargin = margin < 0 ? 0 : margin;
		},

		setCurrentTime(time) {
			this.$parent.setCurrentTime(time);
		},

		// Used in editor mode
		dragTag(e, tag) {
			drag(e, tag, time => {
				this.setCurrentTime(time);
			});
		},

		resizeTag(e, tag) {
			resize(e, tag, time => {
				this.setCurrentTime(time);
			});
		}
	}
};
</script>
